<template>
    <r-e-dialog title="分配角色" show-footer class="layer-contract-detail" :visible.sync="dialogVisible" top="10vh"
                @click-submit="clickSubmit" @click-cancel="clickCancel" @close="clickCancel" width="600px">
        <el-form ref="formPublish" label-width="120px" size="small" :model="formPublish">
            <div class="flex">
                <el-form-item label="账号">
                    <el-input v-model="formPublish.username" disabled style="width: 400px;" placeholder="请填写角色名称"/>
                </el-form-item>
            </div>
            <div class="flex">
                <el-form-item label="角色">
                    <el-checkbox-group v-model="formPublish.roles">
                        <el-checkbox v-for="(item,index) in rolesList" :key="index" :label="item.name">{{item.comment}}</el-checkbox>
                    </el-checkbox-group>
                </el-form-item>
            </div>
        </el-form>
    </r-e-dialog>
</template>

<script>
    import { userRoleUpdate } from "@/api/personnel-management";
    import {getRoleListNopage} from "@/api/role";
    import {MessageSuccess, MessageError} from "@custom/message";
    export default {
        name: "dialog-save-user-role",
        data() {
            return {
                dialogVisible: false,
                formPublish: {
                    username: null,
                    roles:[]
                },
                rolesList: []
            }
        },
        mounted() {
            this.getRoleListNopage();
        },
        methods: {
            openDialog(data) {
                if (data) {
                    let {username, roles} = data;
                    if (roles && roles.length !== 0) roles = roles.map(item => item.name);
                    this.formPublish = {username, roles};
                }
                this.dialogVisible = true;
            },
            clickCancel() {
                this.$refs["formPublish"].resetFields();
                this.formPublish = {username: null, roles:[]};
                this.$emit('handleSearch');
                this.dialogVisible = false;
            },
            clickSubmit() {
                let that = this;
                // 表单提交
                that.$refs["formPublish"].validate((valid) => {
                    if (valid) {
                        let {username, roles} = that.formPublish;
                        const loading = this.$loading({lock: true, text: 'Loading', spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.7)'});
                        userRoleUpdate({username, roles}).then(res => {
                            loading.close();
                            MessageSuccess("分配成功");
                            that.clickCancel();
                        }).catch(err => loading.close());
                    }
                });
            },
            getRoleListNopage(){
                let that = this;
                getRoleListNopage().then(res=>{
                    let {list} = res;
                    that.rolesList = list;
                });
            }
        }
    }
</script>

<style scoped>

</style>